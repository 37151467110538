.App {
  text-align: center;
  background-color: white;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: black;
  font-family: "Times New Roman", serif;
}

@media screen and (max-width: 360px) {
  .App {
    max-width: 95%;
  }
}